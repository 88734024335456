// App.js
import React, { useEffect } from 'react';
import VideoBackground from './components/VideoBackground';
import ScrollItems from './components/ScrollItems';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Students from './sections/Students';
import Footer from './components/Footer';
import "@fontsource/montserrat";
import Blog_1 from './blogs/Blog_1';
import BlogDashboard from './blogs/BlogDashboard';
import Aitutor from './blogs/Aitutor';
import Education from './blogs/Education';
import Repetition from './blogs/Repetition';
import Shadows from './blogs/Shadows';
import Kaleidoscope from './blogs/Kaleidoscope';
import Matrix from './blogs/Matrix';
import Neuroscience from './blogs/Neuroscience';
import ResearchDashboard from './research/ResearchDashboard';
import Aboutus from './about_us/Aboutus';


const App = () => {

  // const [textData, setTextData] = useState({});

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('/landingPageData.json');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setTextData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const dynamicVideoLink = "videos/Rethink School Landing 1 Vid.mp4";
  const pageName = "mainPage"
  const buttonText = "About Rethink School"

  useEffect(() => {
    window.addEventListener('resize', adjustHeightWidth);
    adjustHeightWidth(); 

    return () => {
      window.removeEventListener('resize', adjustHeightWidth);
    };
  }, []);

  const adjustHeightWidth = () => {
    const viewportHeight = window.innerHeight; 
    const viewportWidth = window.innerWidth; 

    const setElementSize = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.style.height = `${viewportHeight}px`;
        element.style.width = `${viewportWidth}px`;
      }
    };
  
    setElementSize("mainPage");
  } 
  return (
    <Router>
      <div className='mainSectionDiv'>
        <Navbar />
        
        <Routes>
          <Route path="/parents" element={<Students dataPath="/parentData.json" />} />
          <Route path="/students" element={<Students dataPath="/studentData.json" />} />
          <Route path="/corporations" element={<Students dataPath="/corporationData.json" />} />
          <Route path="/videoBackground" element={<VideoBackground />} />
          <Route path="/administrators" element={<Students dataPath="/administratorData.json" />} />
          <Route path="/teachers" element={<Students dataPath="/teacherData.json" />} />
          <Route path="/tutors" element={<Students dataPath="/tutorData.json" />} />
          <Route path="/features/adaptivelearning" element={<Students dataPath="/adaptiveLearningData.json" />} />
          <Route path="/features/assessmentinsights" element={<Students dataPath="/assesmentInsightData.json" />} />
          <Route path="/features/attendancemonitoring" element={<Students dataPath="/attendanceMonitoringData.json" />} />
          <Route path="/features/datainsights" element={<Students dataPath="/dataInsightData.json" />} />
          <Route path="/features/focus-drivencontent" element={<Students dataPath="/focusDrivenData.json" />} />
          <Route path="/features/professionaldevelopment" element={<Students dataPath="/professionalDevelopmentData.json" backgroundColor="#1c2933" />} />
          <Route path="/blog" element={<Blog_1 />} />
          <Route path="/blogdashboard" element={<BlogDashboard dataPath="/blogDashboardData.json" />} />
          <Route path="/blog1" element={<Aitutor />} />
          <Route path="/blog2" element={<Education />} />
          <Route path="/blog3" element={<Repetition />} />
          <Route path="/blog4" element={<Shadows />} />
          <Route path="/blog5" element={<Kaleidoscope />} />
          <Route path="/blog6" element={<Matrix />} />
          <Route path="/blog7" element={<Neuroscience />} />
          <Route path="/research" element={<ResearchDashboard dataPath="/researchDashboardData.json"/>} />
          <Route path="/about-us" element={<Aboutus />} />

          <Route path="/" element={<>
            <VideoBackground
              videoLink={dynamicVideoLink}
              buttonText={buttonText}
              pageName={pageName}
            />
            <ScrollItems />
          </>} />
        </Routes>

        <Footer /> 
      </div>
    </Router>
    
  );
};

export default App;
