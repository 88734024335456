import React, { useState, useEffect } from 'react';
import '../Fonts/fontello-icon.css';
import '../global.css';
import dropdownOpenIcon from '../assets/Openedfolder.svg';
import dropdownCloseIcon from '../assets/Closed Folder.svg';

const Footer = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    fetch('/landingPageData.json')
      .then((response) => response.json())
      .then((data) => setData(data.footer))
      .catch((error) => console.error('Error fetching footer data:', error));
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Use smooth scrolling
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark-footer' : 'bg-transparent'}`;

  if (!data) {
    return <footer>Loading...</footer>;
  }

  return (
    <footer className="text-white bg-black footer-padding pt-8rem">
      <div className="footer-container vp-container d-flex gap-5 flex-column">
        <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center gap-2 gap-lg-5">
          <div className="logo-container d-inline-flex align-items-center gap-2 maxw-265 w-100 me-xl-5 me-0">
            <h1 className="siteLogo">
              <a href={data.logo.href}>
                <i className={data.logo.iconClass}></i>
              </a>
            </h1>
            <a className="navbar-brand bg-black  common-textshadow montser-lrg m-0" href={data.logo.brandHref}>
              <span>{data.logo.brandName}</span>
            </a>
          </div>
          <ul className="navbar-nav flex-wrap d-flex flex-row align-items-start align-items-md-center me-auto mb-2 mb-lg-0 gap-sm-4 gap-2 footer-menu w-100">
            {data.menu.map((item, index) => (
              <li className="nav-item nav-item-space" key={index}>
                {!item.subMenu ? (
                  <a className="nav-link bg-black  common-textshadow montser-mdm-2" href={item.href}>
                    <span className="text-decorator-underline">{item.name}</span>
                  </a>
                ) : (
                  <div className="nav-item dropdown">
                    <a
                      className="nav-link bg-black d-flex align-items-center gap-2 montser-mdm-2 common-textshadow"
                      href="/"
                      id={`navbarDropdown${index}`}
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={handleDropdownToggle}
                    >
                      <span className="text-decorator-underline">{item.name}</span>
                      <div className={`${isDropdownOpen ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                        <i className="demo-icon icon-opened-folder dropdown-icon-size"></i>
                      </div>
                    </a>
                    <ul className={dropdownMenuClassName} aria-labelledby={`navbarDropdown${index}`}>
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <a className="dropdown-item-effect montser-mdm-2 bg-black" href={subItem.href}>
                            {subItem.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-start gap-3 gap-md-5 align-items-md-center mt-4 mt-md-0">
        <div className="nav-item minw-265 w-100 me-xl-4 ms-xl-4 me-0 text-start">
          <a className="nav-link bg-black bg-dark-footer montser-mdm-2 common-textshadow" href="/">
            <span>{data.footerNote}</span>
          </a>
        </div>

          <ul className="navbar-nav  d-flex align-center me-auto mb-2 mb-lg-0 flex-row gap-4">
            {data.socialLinks.map((link, index) => (
              <li className="nav-item" key={index}>
                <a className="nav-link bg-black bg-dark-footer copy-right-text text-decorator-underline montser-mdm-2 common-textshadow" href={link.href}>
                  <span className="text-decorator-underline">{link.name}</span>
                </a>
              </li>
            ))}
          </ul>
          <ul className="navbar-nav d-flex align-center gap-3 flex-row">
            <li className="nav-item back-to-top">
              <a
                href="#"
                className="nav-link copy-right-text-top bg-black bg-dark-footer montser-mdm-2 common-textshadow pe-auto"
                onClick={scrollToTop}
              >
                <span className="text-decorator-underline">Back to top</span>
                <i className="demo-icon icon-up-arrow m-1 dropdown-icon-size"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
