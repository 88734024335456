import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import './aboutus.css';
import VideoBackground from '../components/VideoBackground';

const Aboutus = () => {
  const pageName = "aboutPage";
  const buttonText = "Home";

  const [content, setContent] = useState(null);

  useEffect(() => {
    fetch('/aboutusData.json')
      .then(response => response.json())
      .then(data => setContent(data))
      .catch(error => console.error('Error fetching JSON:', error));


    const elements = document.getElementsByClassName("text-yellow");
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.add(".text-dark1");
    }
    const section = document.getElementsByClassName("mainSectionDiv")[0];
    section.classList.add("aboutSectionActive");
  }, []);

  return (
    <div className='p-0'>
      {content && (
        <VideoBackground
          videoLink={content.videoLink}
          buttonText={buttonText}
          pageName={pageName}
        />
      )}
      <div style={{ height: '100%', paddingTop: '20px' }} className='bg-body-about bg-black'>
        <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
          <div className='content-margin '>
            {content && content.sections.map((section, index) => (
              <div key={index}>
                <h4 className='mb-1 custom-large-heading section-margin'>{section.heading}</h4>
                {section.paragraphs.map((paragraph, idx) => (
                  <p key={idx} className='align-items-center monster-lrg-normal p-gap '>
                    {paragraph}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
