import React, { useEffect, useState } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';
import MyPopupbutton from '../react-calendly/MyPopupbutton';


const Students = ({ dataPath}) => {
    const [jobData, setJobData] = useState([]);
    const [sections, setSections] = useState([]);
    const [mainImage, setMainImage] = useState('');
    const [wideImage, setWideImage] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [jobLabel, setJobLabel] = useState('');
    const [sectionHeading, setSectionHeading] = useState('');
    const [bgColor,setBgColor] =  useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(dataPath); 
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setJobData(data.jobData);
                setSections(data.sections);
                setMainImage(data.mainImage);
                setWideImage(data.wideImage);
                setHeaderText(data.headerText);
                setJobLabel(data.jobLabel); 
                setSectionHeading(data.sectionHeading); 
                setBgColor(data.backgroundColor)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dataPath]); 

    const isLastSectionReversed = sections.length > 0 ? sections[sections.length - 1].reverse : false;

    return (

            <div className=''>
                <div style={{ height: '100%', paddingTop: '100px' }} className={bgColor}>
                    <div className="gap-3 gap-md-5">
                        <div className="vp-container container-fluid">
                            <section>
                                <h4 className='mb-3 custom-large-heading section-margin'>{sectionHeading}</h4>
                            </section>
                            <section className='vp-section pb-5'>
                                <div className='justify-content-center'>
                                    <div className='d-flex flex-column align-items-start text-left'>
                                        <p className='montser-mdm-2 common-textshadow mb-3 vp-container lh-lg text-center ft-3vh'>
                                            {headerText}</p>
                                        <div className="width-100">
                                            <img src={wideImage} alt="image not found" className="rounded-top rounded-0 common-boxshadow student-wide-img" />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='vp-section'>
                                <div className='section-container'>
                                    <div className='row justify-content-center align-items-center'>
                                    <div className='col-md-6 col-12 d-flex flex-column'>
                                        {jobData.map((job, index) => (
                                        <div key={index} className='mb-5'>
                                            <div className='d-flex flex-row align-items-center gap-3'>
                                            <div className='job-width'>
                                                <h5 className='common-textshadow montser-mdm-2 ft-4vh'>{jobLabel}</h5>
                                                <span className='montser-mdm-2 common-textshadow ft-2-5vh'>{job.title}</span>
                                                <p className='montser-sml-LightItalic common-textshadow ft-2-3vh'>{job.description}</p>
                                            </div>
                                            <div><i className='demo-icon icon-checkiconsolid' /></div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                    </div>
                                </div>
                            </section>
                            <div className="vp-container container-fluid">
                                <div className='section-container flex-column'>
                                    {sections.map((section, index) => (
                                        <section key={index} className='vp-section'>
                                            <div className='row align-items-center'>
                                                <div className={`col-sm-5 ${section.reverse ? 'order-md-last' : ''}`}>
                                                    <div className='w-100 maxw-450 ms-auto me-auto d-flex mt-5'>
                                                        <img src={section.image} alt="image not found" className="w-100 object-fit-cover img-fluid rounded-top rounded-0 common-boxshadow img-contain section-img ms-auto" />
                                                    </div>
                                                </div>
                                                <div className={`col-sm-7 ${section.reverse ? 'order-md-first' : ''} mt-5 mt-sm-0`}>
                                                    <div className='inner-container gap-5'>
                                                        <div>
                                                            <p className="montser-sml-LightItalic lh-lg ft-3vh">
                                                                {section.text}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    ))}
                                    <div className={`row  ${isLastSectionReversed ? 'justify-content-start' : 'justify-content-end'}`}>
                                        <div className='col-sm-8'>
                                            <div className='mb-5 d-flex align-items-center justify-content-center footer my-popup-button-container'>
                                                <MyPopupbutton />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Students;
