import React, { useState, useEffect } from 'react';
import '../global.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Users = () => {
  const [usersData, setUsersData] = useState({ features: [], blogUrls: [], heading: '' });
  const [startIndex, setStartIndex] = useState(0);
  const cardsPerRow = 4;

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const response = await fetch('/landingPageData.json');
        if (!response.ok) {
          throw new Error('Failed to fetch users data');
        }
        const data = await response.json();
        setUsersData(data.users); // Assuming `users` is the correct key in your JSON data
      } catch (error) {
        console.error('Error fetching users data:', error);
      }
    };

    fetchUsersData();
  }, []);

  const { features, heading } = usersData;

  const nextItems = () => {
    if (startIndex + 1 <= features.length - cardsPerRow) {
      setStartIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevItems = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className="container-fluid bg-black p-0">
      <div className="scroll-items bg-black ">
        <div className="d-flex justify-content-between align-items-center pr-1 vp-container">
          <h2 className="custom-large-heading light-col text-start pt-3 mb-0">{heading}</h2>
          <div>
            <button className="btn btn-outline-primary me-2" onClick={prevItems}>
              <i className="fas fa-arrow-left"></i>
            </button>
            <button className="btn btn-outline-primary" onClick={nextItems}>
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="row text-yellow gx-5 img-spacing mt-3 ">
          {features.slice(startIndex, startIndex + cardsPerRow).map((feature, index) => (
            <div key={index} className="col-md-6 col-lg-3 my-4">
              <div className="card border-0 bg-transparent position-relative">
                <a href={feature.link} className="text-decoration-none">
                  <img 
                    src={process.env.PUBLIC_URL + feature.imageSrc} 
                    alt="image not found" 
                    className="img-fluid rounded-top rounded-0 common-boxshadow"
                  />
                  <div className="card-overlay">
                    <span className="scroll-item mb-1 text-yellow scroll-text montser-mdm-2 common-textshadow">
                      {feature.title}
                    </span>
                    <span className="scroll-item mb-1 text-yellow montser-sml-LightItalic">
                      {feature.description}
                    </span>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Users;
