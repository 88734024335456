import React, { useState, useEffect } from 'react';
import '../global.css';
import '../Fonts/fontello-icon.css';

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/landingPageData.json'); // Assuming landingPageData.json is in the public folder
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    const handleDocumentClick = (e) => {
      if (!isNavbarCollapsed && !e.target.closest('.navbar')) {
        setIsNavbarCollapsed(true);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavbarCollapsed]);

  const handleNavbarToggle = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleJoinClick = () => {
    window.open("https://rethink.virtuprep.com", '_blank');
  };

  const handleLoginClick = () => {
    window.open("https://rethink.virtuprep.com", '_blank');
  };

  const dropdownMenuClassName = `dropdown-menu border-0 ${scrolling ? 'bg-dark' : 'bg-1c2933-90opa'}`;

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    // <nav className={`navbar navbar-expand-lg position-fixed ht-10vh ${scrolling ? 'bg-dark' : ''}`}>
    <nav className="navbar navbar-expand-lg position-fixed ht-10vh bg-black" >
      <div className="container-fluid vp-container gap-3 gap-md-5 no-flex">
        <div className='logo-container d-inline-flex align-items-center gap-0 maxw-265 w-100 me-xl-5 me-0'>
          <h1 className="siteLogo">
            <a href={data.navbar.logo.href}>
              <i className={data.navbar.logo.iconClass}></i>
            </a>
          </h1>
          <a className="navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href={data.navbar.brand.href}>
            <span>{data.navbar.brand.name}</span>
          </a>
        </div>
        <button
          className="navbar-toggler border-0 text-white"
          type="button"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavbarCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavbarToggle}
        >
          <span className={`${scrolling ? 'text-white' : ''}`}>
            <span className="text-virtu-header f-ui-1">Menu</span>
          </span>
        </button>
        <div className={`collapse navbar-collapse mobile-menu mobile-bg-color ${!isNavbarCollapsed ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav d-flex align-items-start align-items-lg-center center me-auto mb-2 mb-lg-0 header-txt-margin gap-4">
            {data.navbar.menu.map((item, index) => (
              <li className="nav-item" key={index}>
                {!item.subMenu ? (
                  <a className="d-flex align-items-center navbar-brand text-virtu-header common-textshadow montser-lrg m-0" href={item.href}>
                    <span className='text-decorator-underline'>{item.name}</span>
                  </a>
                ) : (
                  <div
                    className="d-flex align-items-center gap-2 text-yellow montser-mdm-2 common-textshadow"
                    href="/"
                    id={`navbarDropdown${index}`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => handleDropdownToggle(index)}
                  >
                    <span className='navbar-brand text-virtu-header common-textshadow montser-lrg m-0 nav-item-span nav-context-menu-title'>{item.name}</span>
                    <div className={`${openDropdownIndex === index ? 'rotate-upwards d-flex align-center' : 'd-flex align-center'}`}>
                      <i className='demo-icon icon-opened-folder dropdown-icon-size'></i>
                    </div>
                  </div>
                )}
                {item.subMenu && (
                  <ul className={`dropdown-menu ${openDropdownIndex === index ? 'show' : ''}`} aria-labelledby={`navbarDropdown${index}`}>
                    {item.subMenu.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <a className="dropdown-item-effect text-yellow montser-lrg common-textshadow" href={subItem.href}>
                          <span className='text-decorator-underline'>{subItem.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <ul className="navbar-nav d-flex align-center gap-3 flex-row justify-content-end">
            <li className="nav-item px-2">
              <a onClick={handleLoginClick} className="nav-link montser-lrg m-0 text-yellow d-flex align-center justify-content-center gap-2 cursor-pointer" rel="noopener noreferrer">
                <span className="text-decorator-underline">{data.navbar.auth.loginText}</span>
                <div className="rotated-icon"><i className="demo-icon icon-up-arrow dropdown-icon-size"></i></div>
              </a>
            </li>
            <li className="nav-item join">
              <button type="button" className="outline-lightgreen py-0 montser-lrg m-0 rounded-top rounded-0 btn btn-outline-light" onClick={handleJoinClick}>
                <span className='text-yellow'>{data.navbar.auth.joinText}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
