import React, { useEffect, useRef, useState } from 'react';
import '../global.css';
import './videobackground.css';

const VideoBackground = ({ videoLink, buttonText, pageName }) => {
  const videoRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [textData, setTextData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/landingPageData.json');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setTextData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!isPlaying);
    }
  };

  const handleJoinClick = () => {
    const destinationUrl = buttonText === "Home" ? "/" : "/about-us";
    window.location.href = destinationUrl;
  };

  return (
    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className='m-4'> 
            <video ref={videoRef} autoPlay loop muted playsInline>
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="video-overlay">
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-mdm-italic ft-5vh'>{textData.videoBackground?.mainPage?.firstLine}</h1>
            <h1 className='custom-large-heading light-col common-textshadow mobile-heading montser-mdm-italic ft-5vh mt-5'>{textData.videoBackground?.mainPage?.secondLine}</h1>
            
            <div className="about mt-5">
              <button type="button" className="outline-lightgreen py-0 rounded-top rounded-0 btn-lg btn-block btn btn-outline-light montser-mdm-2 col-ffe6c8" onClick={handleJoinClick}>{buttonText}</button>
            </div>   
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBackground;
